<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Conseil Départemental du Haut-Rhin</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2020</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Développement front-end, webdesign
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Au cours de ma deuxième année de DUT Métiers du Multimédia et de
						l'Internet, j'ai effectué mon stage dans l'administration du Conseil
						Départemental du Haut-Rhin au sein de la Direction des Systèmes
						d'Information et plus précisément dans l'Unité Solutions Web.<br /><br />
						Au sein du Conseil Départemental du Haut-Rhin, on m'a affecté sur un
						projet de convergence du Service d'Information de l'Assemblée
						Départementale afin de pouvoir œuvrer au mieux à la continuité
						d'activité de la nouvelle Collectivité européenne d'Alsace (CeA). Ce
						projet consistait à créer de nouvelles applications regroupant
						toutes les données des deux Conseils Départementaux sur les élus de
						la région ainsi que sur leurs missions. Ces applications ont été
						déployées lors de la création de la CeA début 2021. <br /><br />
						Pour cela, j’y ai réalisé les pages dynamiques des applications du
						référentiel des élus et des missions confiées à ces élus grâce aux
						Web Services créés préalablement par les membres de l’Unité
						Solutions Web. Ainsi, je suis passé tout d'abord par l'outil de
						maquettage Adobe XD afin de faire des propositions de mise en page à
						l'équipe. Par la suite, j'ai effectué le développement front-end des
						pages en utilisant la bibliothèque jQuery, le framework Bootstrap et
						pour finir Ajax afin de récupérer les données des Web Services.
					</p>

					<div
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						style="margin-top: -3vh;"
					>
						<a href="https://www.alsace.eu/" class="boutonVoirSite"
							>LE SITE DE LA COLLECTIVITÉ EUROPÉENNE D'ALSACE</a
						>
					</div>

					<!--Image 1-->

					<img
						src="../assets/conseildepartementalhautrhin/page_elus.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu de la page concernant les élus de la CeA avec l'élu Eric Straumann sélectionné"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu de la page concernant les élus de la CeA avec l'élu Eric
						Straumann sélectionné
					</p>

					<!--Image 2-->

					<img
						src="../assets/conseildepartementalhautrhin/page_commissions.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu de la page concernant les commissions et groupes d'élus avec l'Assemblée Départementale sélectionnée"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu de la page concernant les commissions et groupes d'élus avec
						l'Assemblée Départementale sélectionnée
					</p>

					<!--Image 3-->

					<img
						src="../assets/conseildepartementalhautrhin/page_recherches.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt='Aperçu de la page concernant les recherches et les résultats en ayant coché le domaine "Culture"'
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu de la page concernant les recherches et les résultats en
						ayant coché le domaine "Culture"
					</p>

					<!--Image 4-->

					<img
						src="../assets/conseildepartementalhautrhin/page_organisme.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu de la page d'un organisme en particulier, ici le Service mixte d'aménagement du château du Hohlandsbourg"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu de la page d'un organisme en particulier, ici le Service
						mixte d'aménagement du château du Hohlandsbourg
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Conseil Départemental du Haut-Rhin",
			meta: [
				{
					name: "description",
					content:
						"Découvrez le projet de préparation de la Collectivité européenne d'Alsace auquel Antonin Winterstein a contribué !",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage:
					"CONSEIL DÉPARTEMENTAL DU HAUT-RHIN - CRÉATION D'APPLICATIONS",
				urlImage: require("../assets/projets/fond_conseil_departemental_haut_rhin.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
